import { request } from "@/request/http";

/**
 * @name: 日程页接口
 * @author: camellia
 * @date: 2022-02-28 
 */
export default {
  // 获取下载任务列表
  getDownTaskList: (data) => {
    return request("POST", `/index.php/backDownTask/getDownTaskList`, data);
  },
  // 删除下载任务
  delDownTask: (data) => {
    return request("POST", `/index.php/backDownTask/delDownTask`, data);
  },
};