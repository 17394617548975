
import DownLoad from "../../../../components/DownLoad.vue";
{/* <script src="http://cdn.highcharts.com.cn/highcharts/highcharts.js"></script> */}
let echarts = require('echarts/lib/echarts');
// 引入柱状图组件
require("echarts/lib/chart/bar");
// // 引入饼图组件
// require('echarts/lib/chart/pie');
import { TitleComponent,TooltipComponent,GridComponent   } from 'echarts/components';
import API from '@/api/manager/exp/expManager';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "virtualExp",
  components: {
    DownLoad
  },
  data() {
    return {
      virtualDate:[],
      activeName:'first',
      expList:[],
      exp_id:'',
      // 虚拟试验详情
      AddFormData:{
        article_id:'',
        des:'',
        pic:[],
        logo:'',
        parameter:[]
        // id:'',
        // name:'',
        // helpurl:'',
        // lab_id:'',
        // cost:'',
        // outday:0,
        // CID:'',
        // status:'',
        // sign:'',
        // basics_total:'',
        // basics_browse:'',
        // create_uid:'',
        // school_id:''
      },
      parameter:[],
      // 上传图片接口地址
      uploadImagePic: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/adminExp/uploadImagePic",
      // 预览图片url
      dialogImageUrl: '',
      dialogVisible: false,
      fileList:[],
      articleLoading:true,
      articleList:[],
      parameter_table:[],
      schoolList : '',
      personalNumber : '',
      personalTimes : '',
      PieChart:{},
      table:[],
      page:1,
      showCharts:false,
      showListNumber:0,
      staticDialog:false,
      census_browse:0,
      census_total:0,
      first:true
    };
  },
  created() {
    echarts.use([TitleComponent]);
    echarts.use([TooltipComponent ]);
    echarts.use([GridComponent ]);
    
    this.exp_id = this.$router.currentRoute.query.exp_id?this.$router.currentRoute.query.exp_id:'';
    // this.getDeveloperExpList();
    this.getstatisData();
    
  },
  mounted(){},
  methods: {
    jumpFeedData()
    {
      let exp_id = this.exp_id ? this.exp_id : this.expList[0].id;
      window.open("https://app.mools.net/backend/public/index.php/developer/exportDeveloperStatistics?exp_id="+exp_id+"&start_time="+this.virtualDate[0]+"&end_time="+this.virtualDate[1]);
    },
    getNextData(e)
    {
      let self = this;
      self.page = e
      // this.getstatisData()
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.$router.push({
            path: "/manager/managerExpStat",
            query: {
              exp_id: self.exp_id,
              hideMenu:1
            },
          }).then(()=>{
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
          nprogressClose();
          
        })
    },
    /**
     * @name: 绘制echarts柱形图
     * @author: camellia
     * @date: 2021-05-25
     */
    drawEchartsBar1() {
      let self = this;
      let arr1 = [];
      let arr2 = [];
      self.table?.forEach((item,index)=>{
        arr1.push("关卡" + (index + 1));
        arr2.push(parseFloat((item.avgTime-0)));
      })
      let maxY = Math.max(...arr2);
      // let arr1=["关卡1","关卡2","关卡3","关卡4","关卡5","关卡6"]
      // let arr2 = [0.2,0.3,0.5,0.1,0.6,1]
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("bar1"));
      let chart = Highcharts.chart('bar1',{
        chart: {
          type: 'column'
        },
        title: {
          text: '通关平均时间'
        },
        xAxis: {
          categories: arr1,
        },
        yAxis: {
          min: 0,
          max:maxY,
          title: {
            text: '平均时间/min'
          }
        },
        colors:['#3E95B5'],
        series: [{
          name: '平均时间',
          data: arr2,
          pointWidth:15
        }]
      });
    },
    /**
     * @name: 饼状图制作
     * @author: lxy
     * @date: 20210908
     */
    createPie()
    {
      let self = this;
      var options = {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 70,
            beta: 0
          }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            // dataLabels: {
            //   enabled: true,
            //   format: '{point.name}'
            // }
          }
        },
        colors:['#3E95B5', '#F5BD5C'],
        series: [{
          type: 'pie',
          name: '人数比',
          data: [
            ['本校',self.inSchoolNumber],
            ['外校',self.outSchoolNumber],
          ]
        }]
    };
    // 图表初始化函数
    var chart = Highcharts.chart('container', options);
    },

    confirmgetstatisData()
    {
      let self = this;
      self.staticDialog = false;
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.table = res.data.table?res.data.table:[];
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.showListNumber= res.data.showListNumber
          this.createPie();
          this.drawEchartsBar1();
          self.$router.push({
            path: "/manager/managerExpStat",
            query: {
              exp_id: self.exp_id,
              hideMenu:1
            },
          }).then(()=>{
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
          nprogressClose();
          
        })
    },

    getstatisData()
    {
      let self = this;
      if(self.first == true)
      {
        this.first = false;
        return;
      }

      let startTime = new Date(this.virtualDate[0]).getTime();
      let endTime = new Date(this.virtualDate[1]).getTime();
      if((!startTime || !endTime) )
      {
        return this.$Tips({
          // 消息提示内容
          message:'请选择统计时间',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }

      if(endTime - startTime > 31536000000 )
      {
        this.staticDialog = true;
        return;
        // this.$Tips({
        //   // 消息提示内容
        //   message:'时间过长',
        //   // 消息提示类型（success-成功,warning-警告/失败）
        //   messageType:'warning',
        //   displayTime:1500
        // })
      }
      let data = {
        exp_id:self.exp_id ? self.exp_id : self.expList[0].id,
        start_time:self.virtualDate[0],
        end_time:self.virtualDate[1],
        page:self.page
      }
      nprogressStart();
      self.$Loading.show();
      API.getDeveloperStatistics(data)
        .then((res)=>{
          nprogressClose();
          self.$Loading.hide();
          self.schoolList = res.data.showList;
          while(self.schoolList.length < 10)
          {
            self.schoolList.push({
              id: "",
              name: "",
              num: '',
              student: '',
            })
          }
          self.personalNumber = res.data.personalNumber;
          self.personalTimes = res.data.personalTimes;
          self.census_browse = res.data.census_browse;
          self.census_total = res.data.census_total;
          self.table = res.data.table?res.data.table:[];
          self.PieChart = res.data.PieChart;
          self.inSchoolNumber = res.data.PieChart.inSchoolNumber
          self.outSchoolNumber = res.data.PieChart.outSchoolNumber
          self.showListNumber= res.data.showListNumber
          this.createPie();
          this.drawEchartsBar1();
          self.$router.push({
            path: "/manager/managerExpStat",
            query: {
              exp_id: self.exp_id,
              hideMenu:1
            },
          }).then(()=>{
            // self.page = self.$router.currentRoute.query.page
            self.updateCssStyle(self.exp_id);
          });
        })
      
    },

    getDeveloperExpData()
    {
      let self = this;
      let data = {
        exp_id : self.exp_id ? self.exp_id:self.expList[0]?.id
      }
      nprogressStart();
      self.$Loading.show();
      self.fileList = [];
      API.getDeveloperExpData(data)
          .then((res)=>{
            nprogressClose();
            self.$Loading.hide();
              if(res.code > 0)
              {
                self.articleList.push({
                  id:res.info.article_id,
                  title:res.info.articleName
                })  
              let pic = ''
              try {
                pic = JSON.parse(res.info.pic);
              } catch (error) {
                console.log(error);
              } 
              if(pic)
              {
                  pic?.forEach((item)=>{
                      self.fileList.push({
                          name:'',
                          url:item
                      })
                      // console.log(item);
                  })
              }
              for (const key in self.AddFormData) {
                self.AddFormData[key] = res.info[key]
              }
              try {
                self.AddFormData.parameter = JSON.parse(res.info.parameter)?JSON.parse(res.info.parameter):[];
                self.parameter = JSON.parse(JSON.stringify(self.AddFormData.parameter));
                let table = JSON.parse(res.info.parameter_table);
                self.parameter_table = JSON.parse(table);
              } catch (error) {
                console.log(error);
              }
              self.parameter?.forEach((item,index)=>{
                for (const key in item) {
                  if(item[key]?.substring(0, 1) == '#')
                  {
                    self.parameter[index][key] = '';
                  }
                }
              
                // item?.forEach((value,key)=>{
                //   if(value?.substring(0, 1) == '#')
                //   self.parameter[index][key] = ''
                // })
              })
              self.$router.push({
                path: "/manager/managerExpStat",
                query: {
                  exp_id: self.exp_id,
                  hideMenu:1
                },
              }).then(()=>{
                // self.page = self.$router.currentRoute.query.page
                self.updateCssStyle(self.exp_id);
              });
              }
              else
              {
                  self.$Tips({
                      // 消息提示内容
                      message:res.msg,
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType:'warning',
                      displayTime:1500
                  })
              }
          })
    },
    /**
     * @name: 获取虚拟实验列表
     * @author: lxy
     * @date: 20210908
     */
    getDeveloperExpList()
    {
      let self = this;
      self.$Loading.show();
      let data = {}
      API.getDeveloperExpList(data)
        .then((res)=>{
            if(res.code > 0)
            {
              if(!self.virtualDate[0]||!self.virtualDate[1])
              {
                self.$Loading.hide();
                return;
              }
              this.getstatisData()

              self.$Loading.hide();
            }
            else
            {
              self.$Loading.hide();
              self.$Tips({
                  // 消息提示内容
                  message:res.msg,
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  displayTime:1500
              })
            }
        })
          
          
    },

    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) {
        if (d[p].id != id) {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他班级*/
          d[p].classList.remove("selectTr");
        } else {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的班级*/
          d[p].classList.add("selectTr");
        }
      }
    },

    jumpFeedBack()
    {
      let url = "#/teacher/developer/feedBack?exp_id="+this.exp_id;
      console.log(url);
      window.open(url);
    }
  },
  /**
   * @name: 点击tap页
   * @author: lxy
   * @date: 20210908
   */
  tabHandleClick({name})
  {
    let self = this;
    self.activeName = name;
    if(name == 'first')
    {
      self.$router.push({
        path: "/teacher/developer/virtualExp",
        query: {
          exp_id: self.exp_id,
          activeName: self.activeName,
          start:self.virtualDate[0],
          end:self.virtualDate[1],
          page:self.page
        },
      }).then(()=>{
        let exp_id = self.exp_id?self.exp_id:self.expList[0].id
        self.updateCssStyle(exp_id)
        if(self.virtualDate[0]&&self.virtualDate[1])
        {
          self.getstatisData()
        }
      });
      
    }
    else
    {
      self.$router.push({
        path: "/teacher/developer/virtualExp",
        query: {
          exp_id: self.exp_id,
          activeName: self.activeName,
          start:self.virtualDate[0],
          end:self.virtualDate[1],
          page:self.page
        },
      }).then(()=>{
        self.getDeveloperExpData()
      });
    }      
  },

};