<template>
    <div class="downLoad">
        <el-button type="primary" icon="el-icon-download" circle @click="showDownLoadDialog"></el-button>
        <el-dialog
        :modal="false"
        title="下载文件"
        :visible.sync="downLoadShow"
        width="50%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="closeDownLoad">
    <el-table
        :data="downLoadList"
        style="width: 100%">
        <el-table-column
            type="index">
        </el-table-column>
        <el-table-column
            prop="title"
            label="任务名">
        </el-table-column>
        <el-table-column
            label="进度">
            <template slot-scope="scope">
                
                <!-- <el-progress v-if="!scope.row.down_url"  :text-inside="true" :stroke-width="17" :percentage="70" status="success"></el-progress> -->
                <div class="loading" v-if="!scope.row.down_url"></div>
                <div class="loadingText" v-if="!scope.row.down_url">下载中请稍后...</div>
                <el-tag v-else type="success">下载成功</el-tag>
            </template>
        </el-table-column>
            <el-table-column width="200">
            <template slot-scope="scope">
                <!-- <el-button  type="primary" @click="deleteDownLoad(scope.row.id)" :disabled="typeof(scope.row.down_url) == 'object' ? true : false">删除</el-button> -->
                <el-button  type="success" :disabled="scope.row.down_url  ? false : true" @click="gotoDownLoad(scope.row.down_url)">下载</el-button>
                
            </template>
        </el-table-column>
        </el-table>
            <span slot="footer" class="dialog-footer">
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { Message } from "element-ui"; 
import API from '@/api/components/DownLoad'
export default {
    data() {
        return {
            // 下载文件列表
            downLoadList: [],
            // 控制下载弹出框显示与隐藏
            downLoadShow:false,
            // 获取下载列表轮询定时器
            downLoadInterval:'',
            confirmVisible:false
        };
    },
    created() {
      
    },
    methods: {
        /**
         * @name: 展示下载文件弹出框
         * @author: lxy
         * @date: 2022-03-14
         */
        showDownLoadDialog()
        {
            this.downLoadShow = true;
            this.getDownTaskList();
            this.downLoadInterval = setInterval(()=>{
                this.getDownTaskList();
            },10000)
        },
        /**
         * @name:  获取下载任务列表
         * @author: lxy
         * @date: 2022-03-14
         */
        getDownTaskList()
        {
            try 
            {
                API.getDownTaskList()
                    .then((result)=>{
                        if(result.code > 0 )
                        {
                            this.downLoadList = result.list;
                            // 检查文件任务是否全部下载完成
                            this.checkInterval(result.list)
                        }
                        else
                        {
                            // Message.error('系统错误');
                            this.$Tips({
                                // 消息提示内容
                                message:"系统错误",
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'warning',
                                button:[
                                    {
                                        type:1,
                                        text:'知道了'
                                    }
                                ]
                            })
                        }
                    })
            } 
            catch (error) 
            {
                // Message.error('系统错误');
            }
        },
        /**
         * @name:  删除下载任务
         * @author: lxy
         * @date: 2022-03-14
         */
        // deleteDownLoad(task_id)
        // {
        //     try 
        //     {
        //         let data = {
        //             task_id : task_id
        //         }
        //             API.delDownTask(data)
        //                 .then((result)=>{
        //                         if(result.code > 0 )
        //                         {
        //                             Message.success(result.msg);
        //                             this.confirmVisible = false
        //                             this.getDownTaskList();
        //                         }
        //                         else
        //                         {
        //                             Message.error('系统错误');
        //                         }
        //                 })
        //     } 
        //     catch (error) 
        //     {
        //         Message.error('系统错误');
        //     }
        // },
        /**
         * @name:  下载文件
         * @author: lxy
         * @date: 2022-03-14
         */
        gotoDownLoad(url)
        {
            window.open(url)
        },
        /**
         * @name:  清除获取下载任务轮询定时器
         * @author: lxy
         * @date: 2022-03-14
         */
        closeDownLoad()
        {
            clearInterval(this.downLoadInterval)
        },
        /**
         * @name:  检查文件是否全部下载完成，全部完成后清除定时器
         * @author: lxy
         * @date: 2022-03-14
         */
        checkInterval(list)
        {
            // console.log(list);
            // down_url == null 代表后台没有下载完成
            let check = list.findIndex((item)=>{
                return typeof(item.down_url) == 'object' || item.down_url==""
            })
            if(check == -1 )
            {
                this.closeDownLoad()
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.loading {
  // display: inline-block;
  float: left;
  width: 20px;
  height: 20px;
  border: 2px solid #0D5570;
  border-top-color: transparent;
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}
.loadingText{
  // display: inline-block;
  margin-left: 10px;
  float: left;
  line-height: 20px;
  color: #0D5570;
}
// 转转转动画
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.downLoad{
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    z-index: 2015;
    >.el-button{
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    }
    .el-table{
        .el-button--primary,.el-button--success{
            // transform: translateX(50%);
            float: right !important;
        }
        .el-button--success{
            margin-right: 1rem;
        }
    }

}


</style>
<style>
.MessageBox{
     z-index:10000 !important;

}
</style>