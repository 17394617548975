import { request } from "@/request/http";

/**
 * @name: 虚拟实验分类管理接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },

  getExpLabList: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabList`, data);
  },

  updateExp: (data) => {
    return request("POST", `/index.php/adminExp/updateExp`, data);
  },

  getExpList: (data) => {
    return request("POST", `/index.php/adminExp/getExpList`, data);
  },

  getArticleList: (data) => {
    return request("POST", `/index.php/adminExp/getArticleList`, data);
  },

  updateExpLabData: (data) => {
    return request("POST", `/index.php/adminExp/updateExpLabData`, data);
  },

  getExpLabDataById: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabDataById`, data);
  },

  delExpById: (data) => {
    return request("POST", `/index.php/adminExp/delExpById`, data);
  },

  updateSortBy: (data) => {
    return request("POST", `/index.php/adminExp/updateSortBy`, data);
  },

  updateExpStatistics: (data) => {
    return request("POST", `/index.php/adminExp/updateExpStatistics`, data);
  },

  updateExpFontend: (data) => {
    return request("POST", `/index.php/adminExp/updateExpFontend`, data);
  },

  updateExpTop: (data) => {
    return request("POST", `/index.php/adminExp/updateExpTop`, data);
  },

  getDeveloperStatistics: (data) => {
    return request("POST", `/index.php/adminExp/getDeveloperStatistics`, data);
  },

  getDeveloperExpData: (data) => {
    return request("POST", `/index.php/developer/getDeveloperExpData`, data);
  },

  getDeveloperExpList: (data) => {
    return request("POST", `/index.php/developer/getDeveloperExpList`, data);
  }
};